import { colorFamilyToTokenBorderColor } from '../../utils/color/legacyColorFamily';
const DEFAULT_BACKGROUND_COLOR = 'fill-brand-default';
const backgroundColorMapping = Object.assign({}, colorFamilyToTokenBorderColor, {
  // we are intentionally using colorFamilyToTokenBorderColor rather than colorFamilyToTokenBackgroundColor because it aligns closely to the legacy badge color mapping
  /**
   * Overrides
   */
  calypso: 'fill-secondary-default',
  'candy-apple': 'fill-alert-default',
  lorax: DEFAULT_BACKGROUND_COLOR,
  oz: 'fill-positive-default',
  /**
   * Custom
   */
  beta: 'fill-accent-purple-default',
  new: 'fill-positive-default'
});
export const CountStyles = {
  display: 'inline-block',
  backgroundColor: 'fill-accent-magenta-default',
  borderInlineWidth: '100',
  borderBlockWidth: '100',
  borderInlineStyle: 'solid',
  borderBlockStyle: 'solid',
  borderInlineColor: 'border-primary-on-fill',
  borderBlockColor: 'border-primary-on-fill',
  borderStartStartRadius: '300',
  borderStartEndRadius: '300',
  borderEndStartRadius: '300',
  borderEndEndRadius: '300',
  paddingInline: '100',
  maxBlockSize: '21px',
  minInlineSize: '21px'
};
export const getBadgeStyles = use => {
  var _backgroundColorMappi;
  return {
    display: 'inline-block',
    borderStartStartRadius: '100',
    borderStartEndRadius: '100',
    borderEndStartRadius: '100',
    borderEndEndRadius: '100',
    minBlockSize: '18px',
    backgroundColor: (_backgroundColorMappi = backgroundColorMapping[use]) !== null && _backgroundColorMappi !== void 0 ? _backgroundColorMappi : DEFAULT_BACKGROUND_COLOR,
    paddingInline: '200'
  };
};